<template>
    <div class="vstack gap-4">
        <MenuTemplates :user="user" :setMenu="setMenu" @showTemplates="showTemplates = $event" />
        <div class="vstack gap-4" v-if="!showTemplates || menu">
            <div class="bg-white p-4 rounded shadow-sm">
                <b-form class="d-flex justify-content-between align-items-center" @submit.prevent="setForm">
                    <b-form-group label="Tipo de ChatBot:" label-class="text-dark" class="text-secondary">
                        <b-form-radio-group class="d-flex" v-model="form.chatbotType" v-if="user?.channelConfig?.modules?.linearChatbot">
                            <b-form-radio class="d-inline-flex me-3" value="1">
                                <div class="ms-2">Chatbot Fluxo/URA</div>
                            </b-form-radio>
                            <b-form-radio class="d-inline-flex me-3" value="4">
                                <div class="ms-2">Chatbot Linear</div>
                            </b-form-radio>
                        </b-form-radio-group>
                        <b-form-radio-group class="d-flex" v-model="form.chatbotType" v-else>
                            <b-form-radio class="d-inline-flex me-3" value="1">
                                <div class="ms-2">Menu Simples</div>
                            </b-form-radio>
                            <b-form-radio class="d-inline-flex me-3" value="2" v-if="user.channel?.apiType == 'cloud' || user.channel?.apiType == 'gupshup'">
                                <div class="ms-2">Lista Interativa</div>
                            </b-form-radio>
                            <b-form-radio class="d-inline-flex" value="3">
                                <div class="ms-2">Botões Interativos</div>
                            </b-form-radio>
                        </b-form-radio-group>
                    </b-form-group>
                    <div>
                        <b-button type="submit" class="managemenubtngreen">Selecionar</b-button>
                    </div>
                </b-form>
            </div>
            <span v-if="chatbotType == 1">
                <ManageMenu @msg="emitMsg" :user="user" :menuId="menu?._id" />
            </span>
            <span v-else-if="chatbotType == 2">
                <ManageList @msg="emitMsg" :user="user" :menuId="menu?._id" />
            </span>
            <span v-else-if="chatbotType == 3">
                <ManageButton @msg="emitMsg" :user="user" :menuId="menu?._id" />
            </span>
            <span v-else-if="chatbotType == 4">
                <ManageMenuLinear @msg="emitMsg" :user="user" :menuId="menu?._id" />
            </span>
        </div>
    </div>
</template>

<script>
import ManageMenu from './managemenu.vue'
import ManageList from './manageList.vue'
import ManageButton from './manageButton.vue'
import ManageMenuLinear from './manageMenuLinear.vue'
import api from '../services/apiService.js'
import MenuTemplates from './menuTemplates.vue'

export default {
    components:{
        ManageMenu, ManageList, ManageButton, ManageMenuLinear,
        MenuTemplates,
    },
    props: ['user'],
    mounted() {
        this.getMenus()
    },
    methods: {
        setForm() {
            this.chatbotType = this.form.chatbotType
        },
        async getMenus() {
            const resp = await api.getMenus(this.user.channelId || this.user.roleId)
            if(resp.statusCode == 200 && resp.menus.length) {
                const mainMenuIndex = resp.menus.findIndex(el => !el.topMenuId)
                const menu = resp.menus[mainMenuIndex]
                if (resp.menus?.filter((el) => !el.topMenuId).length === 1) {
                    console.log('here', menu)
                    this.menu = menu
                }
                this.handleMenu(menu)
            }
        },
        handleMenu(menu) {
            if(!menu.topMenuId)
                menu.topMenuId = undefined
            if(!menu.refObjectMenus)
                menu.refObjectMenus = undefined
            
            console.log('menu',menu)
            if(menu.interactive?.type) {
                switch(menu.interactive.type) {
                    case 'list':
                    this.chatbotType = 2
                    this.form.chatbotType = 2
                    break
                    case 'button':
                    this.chatbotType = 3
                    this.form.chatbotType = 3
                    break
                }
            } else {
                if(menu.isLinear) {
                    this.chatbotType = this.form.chatbotType = 4
                } else {
                    this.chatbotType = this.form.chatbotType = 1
                }
            }
        },
        emitMsg(msg) {
            this.$emit('msg',msg)
        },
        setMenu(menu) {
            console.log({menu})
            this.menu = null
            setTimeout(() => {
                this.menu = menu
                this.handleMenu(menu)
            }, 100)
        }
    },
    data() {
        return {
            form: {},
            chatbotType: null,
            showTemplates: false,
            menu: null
        }
    }
}
</script>

<style scoped>
.managemenubtngreen{
    background-color: hsl(142deg 38% 59%);
    border-color: hsl(142deg 38% 59%);
    padding: 5px 20px;
}
</style>